import _, { reverse } from 'lodash'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
export default {
  name: 'ButtonsWrapper',
  props: {
    options: Object,
    selectedItems: Object,
    reverse: Boolean,
  },
  setup(props, ctx) {
    const container1 = ref(null)
    const visibleButtons = ref(_.cloneDeep(props.options.buttons))
    const menuButtons = ref([])
    const buttonsLength = ref([])
    let hiddenButtonsCount = 0

    const replaceButtons = (toMenu) => {
      if (toMenu) {
        let button = visibleButtons.value.pop()
        if (button) menuButtons.value.push(button)
      } else {
        let button = menuButtons.value.pop()
        if (button) visibleButtons.value.push(button)
      }
    }

    const panelAction = (action, button) => {
      let data = []
      if (props.options.clickHandler) {
        ctx.emit('click', button)
        return
      }
      if (!props.selectedItems?.length) {
        return
      }
      props.selectedItems.forEach((x) => {
        data.push(x.row.id)
      })

      ctx.emit('onAction', {
        data,
        action,
      })
    }

    const currBlockLength = (count = 0) => {
      if (count === -1) return 9999
      if (count >= buttonsLength.value.length - 1) return 0
      let sum = 0
      for (let i = buttonsLength.value.length - count - 1; i >= 0; i--) {
        sum += buttonsLength.value[i]
      }
      return sum
    }

    const calcButtonsPosition = () => {
      while (
        currBlockLength(hiddenButtonsCount) > container1.value.offsetWidth ||
        currBlockLength(hiddenButtonsCount - 1) < container1.value.offsetWidth
      ) {
        if (
          currBlockLength(hiddenButtonsCount) > container1.value.offsetWidth
        ) {
          replaceButtons(true)
          if (hiddenButtonsCount === 0) {
            buttonsLength.value = [118, ...buttonsLength.value]
          }
          hiddenButtonsCount++
        }
        if (
          currBlockLength(hiddenButtonsCount - 1) < container1.value.offsetWidth
        ) {
          replaceButtons(false)
          if (hiddenButtonsCount === 1) {
            buttonsLength.value.shift()
          }
          hiddenButtonsCount--
        }
      }
    }

    function resizeHandler() {
      calcButtonsPosition()
    }

    onMounted(() => {
      hiddenButtonsCount = 0
      visibleButtons.value = [...props.options.buttons]
      menuButtons.value = []
      window.addEventListener('resize', resizeHandler)
      for (let i = 0; i < container1.value.children.length; i++) {
        buttonsLength.value.push(container1.value.children[i].clientWidth + 12)
      }
      calcButtonsPosition()
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      menuButtons,
      visibleButtons,
      buttonsLength,
      panelAction,
      container1,
    }
  },
}
