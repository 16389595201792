import { computed, onMounted, ref, watch } from 'vue'

export default {
  name: 'Custom-Pagination',
  props: {
    totalPages: Number,
    totalVisible: Number,
    value: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const buttonsList = ref([])

    const halfVisible = computed(() => {
      return Math.floor(props.totalVisible / 2)
    })

    function calcButtonsList(page) {
      buttonsList.value = []

      if (props.totalVisible <= 3) {
        buttonsList.value = [page]
        return
      }
      if (props.totalPages === 1) {
        buttonsList.value = [1]
        return
      }
      if (props.totalPages <= props.totalVisible) {
        for (let i = 1; i < props.totalPages + 1; i++) {
          buttonsList.value.push(i)
        }
        return
      }
      if (page <= halfVisible.value) {
        for (let i = 1; i < halfVisible.value + page; i++) {
          buttonsList.value.push(i)
        }
        buttonsList.value.push('...')
        for (
          let i = props.totalPages - halfVisible.value + page;
          i < props.totalPages;
          i++
        ) {
          buttonsList.value.push(i)
        }
        buttonsList.value.push(props.totalPages)
        return
      }

      if (
        page > halfVisible.value &&
        page <= props.totalPages - halfVisible.value
      ) {
        if (props.totalVisible <= 5) {
          buttonsList.value = [1, '...', page, '...', props.totalPages]
          return
        }
        buttonsList.value.push(1, '...')
        for (
          let i = page - halfVisible.value + 2;
          i < page + halfVisible.value - 1;
          i++
        ) {
          buttonsList.value.push(i)
        }
        buttonsList.value.push('...', props.totalPages)
        return
      }

      if (page >= props.totalPages - halfVisible.value) {
        buttonsList.value.push(1)
        for (
          let i = 2;
          i < halfVisible.value + 1 - (props.totalPages - page);
          i++
        ) {
          buttonsList.value.push(i)
        }
        buttonsList.value.push('...')
        for (
          let i =
            props.totalPages +
            1 -
            halfVisible.value -
            (props.totalPages - page);
          i <= props.totalPages;
          i++
        ) {
          buttonsList.value.push(i)
        }
      }
    }
    const changePage = (page) => {
      let pageValue = 0
      if (page === 'left') {
        pageValue = props.value - 1
      } else if (page === 'right') {
        pageValue = props.value + 1
      } else {
        pageValue = page
      }

      ctx.emit('input', pageValue)
      calcButtonsList(pageValue)
    }

    watch(
      () => props.totalPages,
      () => {
        console.log('change')
        // props.value = 1
        calcButtonsList(1)
      }
    )

    watch(
      () => props.totalVisible,
      (x) => {
        calcButtonsList(props.value)
      }
    )

    onMounted(() => {
      calcButtonsList(1)
    })

    return {
      buttonsList,
      changePage,
      halfVisible,
    }
  },
}
