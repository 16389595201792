import { onBeforeMount, onMounted, ref } from 'vue'
import useView from '@/compositions/useView.js'
import { useRoute, useRouter } from 'vue-router/composables'
import useNavigation from '@/compositions/useNavigation'
// import TableFixed from '@/components/Table/fixed/index.vue'

//import Layout from '@/layouts/default/index'
//import Axios from 'axios'

export default {
  name: 'Custom-Tabs',
  props: {
    data: Object,
    value: Number,
  },

  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup(props, ctx) {
    const activeTab = ref(props.value)
    const tabs = ref([])
    const router = useRouter()

    const changeTabRoute = (route) => {
      router.push({ params: { tab: route } })
    }
    onBeforeMount(() => {
      const { setFirstAvailableTab } = useNavigation({
        props,
        context: ctx,
        activeTab,
      })
      setFirstAvailableTab()
    })
    useView({
      tabs,
      activeTab,
    })
    return {
      activeTab,
      tabs,
      changeTabRoute,
    }
  },
}
