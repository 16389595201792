let incrementer = 0
const notifies = {
  namespaced: true,
  state: [],
  // state: {
  //   content: '',
  //   color: 'success',
  //   timeout: null,
  //   isShow: false,
  //   component: null,
  //   data: null,
  // },
  mutations: {
    showMessage(
      state,
      { content, color, timeout, component, data, icon, subtitle }
    ) {
      // state.content = content
      // state.color = color
      // state.timeout = timeout
      // state.component = component
      // state.isShow = true
      // state.data = data
      let timeoutHandler = 0
      if (timeout) {
        timeoutHandler = setTimeout(() => {
          vm.commit('notifies/hideMessage', timeoutHandler)
        }, timeout)
      } else {
        timeoutHandler = setTimeout(() => {
          vm.commit('notifies/hideMessage', timeoutHandler)
        }, 3500)
      }
      if (content || component) {
        state.push({
          id: timeoutHandler,
          content,
          color,
          timeout,
          component,
          isShow: true,
          data,
          icon,
          subtitle,
        })
      }
      const vm = this
    },
    hideMessage(state, id) {
      console.log(id)
      let index = state.findIndex((x) => x.id === id)
      state.splice(index, 1)
      console.log('delete', index)
    },
  },
}

export default notifies
