import _, { reverse } from 'lodash'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import moment from 'moment'
export default {
  name: 'MultiCell',
  // {
  //   title: '',
  //   alias: '',
  //   width: '100',
  //   fixed: {
  //     value: true,
  //     isShow: false,
  //   },
  //   icon: {
  //     icon: '',
  //     color: '',
  //     tooltip: 'zxcxzc',
  //   },
  //   sort: {
  //     alias: 'zxczc',
  //   },
  //   search: {
  //     alias: 'zxczc',
  //   },
  //   rows: [
  //     {
  //       type: '',
  //       // (Text | Action | Tag | Date | CheckBox)
  //       value: '',
  //       // color: '',
  //       class: [''],
  //       align: 'left',
  //       // alias: '',
  //       icon: {
  //         icon: '',
  //         color: '',
  //       },
  //       action: 'zxc',
  //     },
  //   ],
  // }
  props: {
    cell: Object,
    row: Object,
    tableContext: Object,
  },
  setup(props, ctx) {
    const emitClick = (entity) => {
      if (props.tableContext.config.type === 'Fixed') {
        if (entity) {
          ctx.emit('dblclick', entity, {
            ...props.cell,
            key: props.row.row[props.cell.routeParam],
          })
          return
        }
      } else {
        if (!entity) {
          ctx.emit('dblclick', props.row, props.cell)
        }
      }
    }
    const convertData = (val) => {
      if (val.length > 10)
        return moment(val, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY hh:mm:ss')
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }
    onMounted(() => {
      if (props.cell.rows)
        props.cell.rows.forEach((x) => {
          if (!['Text', 'Action', 'Tag', 'Date', 'CheckBox'].includes(x.type))
            x.type = 'Text'
        })
      props.cell.search.isShow ??= true
      props.cell.fixed.isShow ??= true
    })
    return {
      convertData,
      emitClick,
    }
  },
}
