import filters from './filters'
import _ from 'lodash'

function changeSort(config) {
  let heading = config.head.find((x) => x.changeable)
  if (heading.title === 'Линейщик') {
    heading.title = 'Объект'
    heading.alias = 'o.name'
    heading.value = 'object_name'
    heading.routeName = 'magnit_pivot-object'
    heading.routeParam = 'object_id'
    heading.click = undefined
    heading.type = 'default'
    config.options.url = 'get/pagination_pivot/request_magnit_object'
  } else if (heading.title === 'Объект') {
    heading.title = 'Линейщик'
    heading.alias = "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)"
    heading.value = 'personal_name'
    heading.routeName = 'magnit_pivot-personal'
    heading.routeParam = 'personal_id'
    heading.click = undefined
    heading.type = 'default'
    config.options.url = 'get/pagination_pivot/request_magnit_personal'
  }
}

const changeFilter = (context, tab) => {
  if (tab.value === 2) {
    context.paramsQuery.initFilter.push({
      type: 'switch',
      subtype: 'single',
      alias: 'is_my',
    })
  } else if (tab.value === 1) {
    _.remove(context.paramsQuery.initFilter, {
      type: 'switch',
      subtype: 'single',
      alias: 'is_my',
    })
  }
}

export const config = {
  selector: '#mainTable',
  options: {
    selecting: true,
    search: {
      function: null,
    },
    headerFixed: true,
    //url: 'https://dummyjson.com/users',
    url: 'get/pagination_pivot/request_magnit_personal',
    title: 'Отработанные',
    route: 'spent',
    doubleHandlerType: 'cell',
  },
  type: 'TableFixed',
  panel: {
    buttons: [
      {
        label: '',
        class: ['v-table-button--custom'],
        typeLabel: 'Объекты',
        icon: '$IconUpdate',
        function: changeSort,
        backgroundColor: '#ffffff',
        type: 'refresh',
        subtype: 'changeHeads',
      },
      {
        class: ['v-table-button--custom'],
        url: '$IconEdit',
        type: 'switch',
        value: 1,
        refreshTable: true,
        backgroundColor: '#ffffff',
        values: [
          {
            label: 'Все',
            value: 1,
            action: changeFilter,
          },
          {
            label: 'Мои',
            value: 2,
            action: changeFilter,
          },
        ],
        isShow: {
          condition: [
            {
              permissions: [9],
              type: true,
            },
          ],
        },
      },
    ],
    filters: true,
    search: true,
    date: true,
    addedItemsChildrenType: 'object',
  },
  head: [
    {
      id: 1,
      title: 'Менеджер',
      align: 'center',
      type: 'default',
      isShow: true,
      width: '200',
      alias: 'sam.name',
      value: 'manager',
      fixed: {
        value: true,
        position: 'left',
      },
      search: {
        field: '',
        isShow: true,
      },
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      routeParam: 'account_id',
      routeName: 'account',
    },
    {
      id: 2,
      title: 'Линейщик',
      align: 'center',
      type: 'default',
      isShow: true,
      width: '200',
      alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
      value: 'personal_name',
      changeable: true,
      fixed: {
        value: true,
        position: 'left',
      },
      search: {
        field: '',
        isShow: true,
      },
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      routeParam: 'personal_id',
      routeName: 'magnit_pivot-personal',
    },
  ],
  data: {
    rows: [],
    totalRows: null,
    pageLength: 20,
    currentPage: 1,
    totalPages: null,
    footer: null,
  },
  detail: {
    type: 'popup', // String 'popup' or 'page'
    classes: [''], // List class
    width: '800px',
    method: 'get',
    alias: 'personal',
    url: '/get/form/',
    name: '',
    bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
    tabs: [],
    activeTab: null,
  },
  filters,
}

export default config
