<template>
  <CustomTabs :data="config" v-model="activeTab"></CustomTabs>
</template>

<script>
import { ref, toRefs, watch } from 'vue'
import useView from '@/compositions/useView.js'

import { config as realtyConfigOrig } from '@/pages/realty/index'
import _ from 'lodash'
import paymentConfigOrig from '@/pages/payment/index'
import zayavkaConfigOrig from '@/pages/zayavka/index'
import { initPaymentZayavka } from '@/utils/helpers.js'
import CustomTabs from '@/components/Tabs/default/index.vue'

export default {
  name: 'Realty-View',
  components: {
    CustomTabs,
  },
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const config = _.cloneDeep(realtyConfigOrig)
    const activeTab = ref(0)
    const tabs = ref([])
    const {
      initTableConfig,
      createHeadItem,
      convertConfigPanel,
      addCloseButton,
      configRouteConvert,
      convertFormConfig,
    } = useView({
      tabs,
      activeTab,
    })
    configRouteConvert({
      config: config.tabs[0],
      newPath: 'edit',
      settings: {
        index: [1],
      },
    })
    configRouteConvert({
      config: config.tabs[1],
      newPath: 'edit',
      settings: {
        index: [0],
      },
    })

    config.tabs[0].detail.tabs[0].fields.splice(9, 1)

    const { paymentConfig, zayavkaConfig } = initPaymentZayavka(
      paymentConfigOrig,
      zayavkaConfigOrig
    )

    configRouteConvert({
      config: zayavkaConfig.config,
      route: 'zayavka',
      newPath: 'realty-zayavka',
      settings: {
        oldPath: 'id',
      },
    })
    config.tabs[0].detail.tabs.push(zayavkaConfig)
    config.tabs[1].detail.tabs.push(zayavkaConfig)

    return {
      config,
      activeTab,
      tabs,
    }
  },
}
</script>
