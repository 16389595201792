import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import CustomPagination from './pagination/index.vue'

export default {
  name: 'Table-Pagination-Panel',
  props: {
    options: Object,
    paramsQuery: Object,
  },
  components: {
    CustomPagination,
  },
  setup(props) {
    const rightDiv = ref(null)
    const buttonWidth = ref(0)
    const startOfVisibleRows = computed(() => {
      return (
        (props.paramsQuery.currentPage - 1) * props.paramsQuery.countRows + 1
      )
    })

    const visibleButtons = ref(7)

    function calcButtonsPosition() {
      // console.log(
      //   calcSwapBorder(visibleButtons.value - 1),
      //   rightDiv.value.clientWidth,
      //   calcSwapBorder(visibleButtons.value),
      //   visibleButtons.value
      // ) Отладка границ
      while (
        rightDiv.value.clientWidth < calcSwapBorder(visibleButtons.value - 1) ||
        rightDiv.value.clientWidth > calcSwapBorder(visibleButtons.value)
      ) {
        if (
          calcSwapBorder(visibleButtons.value - 1) > rightDiv.value.clientWidth
        ) {
          visibleButtons.value--
        }
        if (
          calcSwapBorder(visibleButtons.value) < rightDiv.value.clientWidth &&
          visibleButtons.value < 7
        ) {
          visibleButtons.value++
        }
      }
    }

    function calcSwapBorder(count) {
      let sum = 0
      if (count <= 0) return 0
      if (count >= 7) return 9999
      for (let i = 0; i < count; i++) {
        sum += buttonWidth.value
      }
      return sum + 135
    }

    const endOfVisibleRows = computed(() => {
      return (
        startOfVisibleRows.value +
        props.paramsQuery.countRows -
        1 -
        (props.paramsQuery.countRows - props.options.rows.length)
      )
    })
    const rowCount = [5, 10, 15, 20, 25, 30]

    onMounted(() => {
      window.addEventListener('resize', calcButtonsPosition)
      buttonWidth.value = 50
      calcButtonsPosition()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', calcButtonsPosition)
    })

    return {
      rowCount,
      endOfVisibleRows,
      startOfVisibleRows,
      rightDiv,
      visibleButtons,
      buttonWidth,
    }
  },
}
