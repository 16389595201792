import filters from './filters'
import { required } from '@/utils/validation.js'
import {
  stringField,
  selectField,
  // autocompleteField,
  dateField,
  checkboxField,
  dropZoneField,
} from '@/utils/fields.js'
import _ from 'lodash'
import { stringAction } from '@/utils/actions'
import FormDefault from '@/components/Form/default/index.vue'
import FormOutput from '@/components/Form/output/index.vue'
import FormTarget from '@/components/Form/target/default/index.vue'
import { editFields as appointmentsFields } from '@/pages/appointments/index.js'
import noteItemCell from './config/noteItemCell.vue'
import noteItemCellFixed from './config/noteItemCellFixed.vue'
import formNoteEdit from './config/form-note-edit.js'
import formNoteAdd from './config/form-note-add.js'

function searchInputing(field) {}

export const config = {
  selector: '#mainTable',
  type: 'Fixed',
  options: {
    selecting: true,
    search: {
      function: searchInputing,
    },
    headerFixed: true,
    //url: 'https://dummyjson.com/users',
    url: 'get/pagination_pivot/personal_target_personal',
    title: 'This is an about page1',
    doubleHandlerType: 'cell',
    cellSizes: {
      heigth: 70,
      width: 70,
    },
    customCell: noteItemCell,
  },
  panel: {
    buttons: [],
    filters: true,
    search: true,
    date: true,
    addedItemsChildrenType: 'object',
  },
  head: [
    {
      id: 1,
      title: 'П/П',
      alias: 'p.id',
      value: 'personal_id',
      fixed: {
        value: true,
        isShow: false,
      },
      rows: [
        {
          type: 'Tag',
          value: 'personal_id',
          align: 'center',
          color: 'info',
          class: ['pa-5'],
        },
      ],
    },
    {
      id: 2,
      title: 'Объект',
      align: 'center',
      type: 'default',
      isShow: true,
      alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
      value: 'object',
      changeable: true,
      // routeParam: 'personal_id',
      // route
      fixed: {
        value: true,
        isShow: false,
      },
      search: {
        field: '',
        isShow: true,
      },
      click: undefined,
      routeParam: 'personal_id',
      routeName: 'personal',
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      customFixedCell: noteItemCellFixed,
    },
    {
      id: 3,
      title: 'Должность',
      align: 'center',
      type: 'default',
      isShow: true,
      alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
      value: 'doljnost',
      changeable: true,
      // routeParam: 'personal_id',
      // route
      fixed: {
        value: true,
        isShow: false,
      },
      search: {
        field: '',
        isShow: true,
      },
      click: undefined,
      routeParam: 'personal_id',
      routeName: 'personal',
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      customFixedCell: noteItemCellFixed,
    },
    {
      id: 4,
      title: 'Сотрудник',
      align: 'center',
      type: 'default',
      isShow: true,
      alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
      value: 'employe',
      changeable: true,
      // routeParam: 'personal_id',
      // route
      fixed: {
        value: true,
        isShow: false,
      },
      search: {
        field: '',
        isShow: true,
      },
      click: undefined,
      routeParam: 'personal_id',
      routeName: 'personal',
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      customFixedCell: noteItemCellFixed,
    },
  ],
  bottomPanel: {
    buttons: [],
    isShow: true,
  },
  data: {
    rows: [],
    totalRows: null,
    pageLength: 20,
    currentPage: 1,
    totalPages: null,
    footer: null,
  },
  detail: {
    type: 'popup', // String 'popup' or 'page'
    classes: [''], // List class
    width: '800px',
    method: 'get',
    alias: 'personal',
    url: '/get/form/',
    name: 'Выработка X5',
    bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
    tabs: [formNoteEdit, formNoteAdd],
    activeTab: null,
  },
  filters,
}

export default config
