<template>
  <CustomTabs :data="{ tabs: availableTabs }" v-model="activeTab"></CustomTabs>
</template>

<script>
import store from '@/store'
import { ref, computed, onMounted } from 'vue'
import _ from 'lodash'
import { config as objectConfig } from '@/pages/object/index'
import { config as formLoadOrig } from '@/pages/payment/config/form-load.js'
import CustomTabs from '@/components/Tabs/default/index.vue'
import useView from '@/compositions/useView.js'
// import TableFixed from '@/components/Table/fixed/index.vue'

//import Layout from '@/layouts/default/index'
//import Axios from 'axios'

export default {
  name: 'Object-View',
  components: {
    CustomTabs,
    // TableFixed,
    //Layout,
  },
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const config = _.cloneDeep(objectConfig)
    const formLoad = _.cloneDeep(formLoadOrig)
    const activeTab = ref(0)
    const tabs = ref([])
    useView({
      tabs,
      activeTab,
    })

    // const checkIncludesPermissions = (el) => {
    //   if (!el.permissions) return true
    //   return el.permissions.includes(permission.value)
    // }
    // const checkIncludesDirections = (el) => {
    //   if (!el.direction_id) return true
    //   return el.direction_id.includes(direction_id.value)
    // }
    const availableTabs = computed(() => {
      return config.tabs.filter((tab) => {
        if (!tab.isShow) return tab
        else {
          return tab.isShow.condition.every((el) => {
            // if (el.permissions) {
            //   return checkIncludesPermissions(el) === el.type
            // } else
            if (el.funcComputed) {
              const context = {
                store,
              }
              return el.funcComputed(context)
            }
          })
        }
      })
    })

    formLoad.fields[0].name = 'path'
    formLoad.fields[0].options.folder = 'tmp'
    formLoad.fields[0].options.name = '`tmp_tarif`'
    formLoad.actions[1].url = 'parser/object_price/xls/list'
    config.tabs[3].detail.tabs.push(formLoad)
    return {
      config,
      activeTab,
      tabs,
      availableTabs,
    }
  },
}
</script>
