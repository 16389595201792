import { computed } from 'vue'
import store from '@/store'

export default {
  name: 'customNotify',
  props: {
    data: Object,
  },
  setup() {
    const hideMessage = (id) => {
      window.clearTimeout(id)
      store.commit('notifies/hideMessage', id)
    }

    return {
      hideMessage,
    }
  },
}
