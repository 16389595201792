var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{class:[_vm.tableContext.config.type === 'Fixed' ? '' : 'pa-3'],style:({
    minWidth: `${_vm.tableContext.config.options?.cellSizes?.width}px`,
    minHeight: `${_vm.tableContext.config.options?.cellSizes?.height}px`,
  }),on:{"dblclick":function($event){return _vm.emitClick(_vm._)}}},[(!_vm.cell.rows)?_c('div',[(_vm.tableContext.config.type === 'Fixed')?_c('div',[(_vm.cell.added)?_c('div',_vm._l((_vm.row.row[_vm.cell.value]),function(entity){return (_vm.tableContext.config.options.customCell)?_c(_vm.tableContext.config.options.customCell,{tag:"component",attrs:{"payload":entity,"cell":_vm.cell},on:{"dblclick":function($event){return _vm.emitClick(entity)}}}):_vm._e()}),1):_c('div',[(_vm.cell.customFixedCell)?_c(_vm.cell.customFixedCell,{tag:"component",attrs:{"payload":_vm.row.row[_vm.cell.value],"cell":_vm.cell}}):_vm._e(),_vm._v(" "+_vm._s(_vm.row.row[_vm.cell.value])+" ")],1)]):_c('div',[(!_vm.cell.icons)?_c('div',[_vm._v(" "+_vm._s(_vm.row.row[_vm.cell.value])+" ")]):_c('v-icon',{attrs:{"color":_vm.cell.icons[_vm.row.row[_vm.cell.value]].color}},[_vm._v(_vm._s(_vm.cell.icons[_vm.row.row[_vm.cell.value]].icon))])],1)]):_vm._e(),_vm._l((_vm.cell.rows),function(field,index){return (_vm.cell.rows)?_c('div',{key:index,class:['row', ...(field.class || []), ...(_vm.cell.class || [])],style:({
      color: !field.color ? 'inherit' : `var(${field.color})`,
    })},[(field.type === 'Text')?[_vm._v(" "+_vm._s(_vm.row.row[field.value])+" ")]:_vm._e(),(field.type === 'CheckBox')?[_c('v-checkbox',{attrs:{"readonly":""},model:{value:(_vm.row.row[field.value]),callback:function ($$v) {_vm.$set(_vm.row.row, field.value, $$v)},expression:"row.row[field.value]"}})]:_vm._e(),(field.type === 'Action')?[_c('v-btn',{attrs:{"disabled":!!field.funcCondition({ ..._vm.tableContext, row: _vm.row }),"x-large":"","icon":"","color":field.color},on:{"click":function($event){return field.action({ ..._vm.tableContext, row: _vm.row })}}},[(field?.icon?.icon)?_c('v-icon',{attrs:{"color":field.icon.color}},[_vm._v(_vm._s(field.icon.icon))]):_vm._e()],1)]:_vm._e(),(field.type === 'Tag')?[_c('div',{staticClass:"pl-2 pr-2 font-weight-500",staticStyle:{"border-radius":"4px"},style:({
          color: `var(--v-${field.color}-base)`,
          backgroundColor: `color-mix(in srgb, var(--v-${field.color}-base) 20%, transparent)`,
        })},[_vm._v(" "+_vm._s(_vm.row.row[field.value])+" ")])]:_vm._e(),(field.type === 'Date')?[_vm._v(" "+_vm._s(_vm.convertData(_vm.row.row[field.value]))+" ")]:_vm._e()],2):_vm._e()})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }