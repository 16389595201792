<template>
  <CustomTabs :data="account" v-model="activeTab"></CustomTabs>
</template>

<script>
import { onMounted, ref } from 'vue'
import { account } from '@/pages'
import useView from '@/compositions/useView.js'
import CustomTabs from '@/components/Tabs/default/index.vue'
// import TableFixed from '@/components/Table/fixed/index.vue'

//import Layout from '@/layouts/default/index'
//import Axios from 'axios'

export default {
  name: 'Account-View',
  components: {
    CustomTabs,
  },
  setup() {
    const activeTab = ref(0)
    const tabs = ref([])
    useView({
      tabs,
      activeTab,
    })
    return {
      account,
      activeTab,
      tabs,
    }
  },
}
</script>
