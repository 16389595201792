<script>
export default {
  props: {
    payload: Object,
    cell: Object,
  },
  setup(props) {
    const typesColor = {
      1: '#D7B12A',
      2: '#A73728',
      3: '#6051BD',
    }
    return {
      typesColor,
    }
  },
}
</script>
<template>
  <div
    class="noteItemCellFixed pa-2"
    @dblclick="() => $emit('dblclick', payload, cell)"
  >
    <v-select outlined class="select"></v-select>
  </div>
</template>
<style lang="scss" scoped>
.noteItemCellFixed {
  display: flex;
  height: 70px;
  flex-flow: column nowrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  &.cell {
    width: 70px;
    border-radius: 4px;
  }

  p {
    text-align: left;
    user-select: none;
    text-overflow: ellipsis;
  }
}
</style>
