import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentView from '../views/PaymentView.vue'
import PaymentListView from '../views/PaymentListView.vue'
import PaymentListPersonalView from '../components/PaymentList/personal/index.vue'
import PersonalView from '../views/PersonalView.vue'
import RealtyView from '../views/RealtyView.vue'
import PivotView from '../views/PivotView.vue'
import Pivotx5View from '../views/Pivotx5View.vue'
import MagnitPivot from '../views/Magnit/PivotView.vue'
import PivotPaymentView from '../views/PivotPaymentView.vue'
import X5importView from '../views/X5importView.vue'
import X5paymentView from '../views/X5paymentView.vue'
import X5territoriesView from '../views/X5territoriesView.vue'
import X5zoneView from '../views/X5zoneView.vue'
import ObjectView from '../views/ObjectView.vue'
import AccountView from '../views/AccountView.vue'
import ZayavkaView from '../views/ZayavkaView.vue'
import AppointmentsView from '../views/Appointments.vue'
import ShopRequestMagnitView from '../views/Magnit/ShopRequestView.vue'
import ShopRequestMagnitReportView from '../views/Magnit/ShopRequestReportView.vue'
import UserKeysView from '../views/UserKeysView.vue'
import TasksView from '../views/Tasks.vue'
import ReportTaxiView from '../views/ReportTaxiView.vue'
import HabitationView from '../views/HabitationView.vue'
import BankView from '../views/BankView.vue'
import JurFaceView from '../views/JurFaceView.vue'
import MvdView from '../views/MvdView.vue'
import ServiceView from '../views/ServiceView.vue'
import RashodCategoryView from '../views/RashodCategoryView.vue'
import RashodVidView from '../views/RashodVidView.vue'
import OfficeView from '../views/OfficeView.vue'
import ReportMagnit from '../views/Magnit/ReportMagnit.vue'
import ReportFilial from '../views/Magnit/ReportFilial.vue'
import MailView from '../views/DefaultMails.vue'
import Navbar from '@/views/Navbar'
import LoginView from '@/views/LoginView'
import Detail from '@/components/Table/detail'
import DocumentView from '@/views/DocumentView.vue'
import SlataReportView from '@/views/SlataReportView.vue'
import CorporateCardsView from '@/views/CorporateCardsView.vue'
import MainView from '@/views/MainView.vue'
import ScheduleView from '@/views/ScheduleView.vue'
import ContractView from '@/views/ContractView.vue'
import query from 'devextreme/data/query'
import NotesView from '@/views/NotesView.vue'
//import TestTs from '@/views/testts'
// import GanttaView from '@/views/GanttaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: '/main',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MainView,
    children: [
      {
        name: '/main/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        children: [
          {
            name: '/main/:id/add',
            path: 'add',
            meta: {
              mode: ['edit', 'zayavka-add'],
            },
            component: Detail,
          },
          {
            name: '/main/:id/:form_id',
            path: ':form_id',
            meta: {
              mode: ['edit', 'zayavka-edit', 'personal-target-edit'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
    ],
  },
  {
    path: '/tasks',
    name: '/tasks',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TasksView,
    children: [
      {
        name: '/tasks/:id',
        path: ':id',
        //mode: ['edit'],
        meta: {
          mode: ['edit'],
        },
        children: [
          {
            name: '/tasks/:id/add',
            path: 'add',
            meta: {
              mode: ['edit', 'zayavka-add'],
            },
            component: Detail,
          },
          {
            name: '/tasks/:id/:form_id',
            path: ':form_id',
            meta: {
              mode: ['edit', 'zayavka-edit'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
    ],
  },
  // {
  //   path: '/vacations',
  //   name: 'vacations',
  //   meta: {
  //     layout: 'blank-layout',
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: GanttaView,
  // },
  {
    path: '/payment',
    name: '/payment',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PaymentView,
    children: [
      {
        name: '/payment/add',
        path: 'add',
        meta: {
          mode: ['add-edit-logistic'],
          label: 'Добавить начисление',
        },
        component: Detail,
      },
      {
        name: '/payment/:id',
        path: ':id',
        meta: {
          mode: ['add-edit-logistic'],
          label: 'Редактировать начисление',
        },
        component: Detail,
        children: [
          {
            name: '/payment/:id/output',
            path: 'output',
            meta: {
              mode: ['add-edit-logistic', 'output'],
              label: 'Редактировать начисление',
            },
            component: Detail,
          },
          {
            name: '/payment/:id/change-personal',
            path: 'change-personal',
            meta: {
              mode: ['add-edit-logistic', 'change-personal'],
              label: 'Сменить линейщика',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/payment/load',
        path: 'load',
        meta: {
          mode: ['load'],
        },
        component: Detail,
      },
      // {
      //   name: 'payment/personal-edit',
      //   path: ':personal_id',
      //   meta: {
      //     mode: ['edit-personal'],
      //     label: 'Редактировать',
      //   },
      //   component: Detail,
      // },
      {
        name: '/payment-personal',
        path: '/payment/:id',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
        children: [
          {
            name: '/payment/:id/:payment',
            path: ':payment',
            meta: {
              mode: ['personal', 'personal-payment'],
            },
            component: Detail,
          },
          {
            name: '/payment/:id/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['personal', 'personal-zayavka'],
            },
            component: Detail,
          },
        ],
      },
    ],
  },
  {
    path: '/payment_list',
    name: '/payment_list',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PaymentListView,
    children: [
      {
        name: 'payment_list/export',
        path: 'export',
        meta: {
          mode: ['export'],
          label: 'Экспорт ЗП',
        },
        component: Detail,
      },
      {
        name: 'payment_list/:object',
        path: ':object',
        meta: {
          label: 'Добавить начисление',
        },
        component: PaymentListPersonalView,
      },
    ],
  },
  {
    path: '/personal_target',
    name: '/personal_target',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AppointmentsView,
    children: [
      {
        name: '/personal_target/add',
        path: '/personal_target/add',
        meta: {
          mode: ['add'],
        },
        component: Detail,
      },
      {
        name: '/personal_target/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/office',
    name: '/office',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: OfficeView,
    children: [
      {
        name: '/office/add',
        path: '/office/add',
        meta: {
          mode: ['add'],
        },
        component: Detail,
      },
      {
        name: '/office/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
      },
      {
        name: '/office/:id/:cardTab',
        path: ':id/:cardTab',
        meta: {
          mode: ['edit'],
        },
        children: [
          {
            name: '/office/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['edit', 'office-zayavka'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
      {
        name: '/office/:office_id',
        path: ':office_id',
        meta: {
          mode: ['target'],
          label: 'Назначить',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/realty',
    name: '/realty',
    meta: {
      layout: 'blank-layout',
    },
    component: RealtyView,
  },
  {
    path: '/realty/:tab',
    name: '/realty/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: RealtyView,
    children: [
      {
        name: '/realty/:tab/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить недвижимость',
        },
        // children: [
        //   {
        //     name: 'realty/:tab/add',
        //     path: ':cardTab',
        //     meta: {
        //       mode: ['add'],
        //       label: 'Добавить недвижимость',
        //     },
        //     component: Detail,
        //   },
        // ],
        component: Detail,
      },
      {
        name: '/realty/:tab/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать недвижимость',
        },
        component: Detail,
      },
      {
        name: '/realty/:tab/:id/:cardTab',
        path: ':id/:cardTab',
        meta: {
          mode: ['edit'],
          label: 'Редактировать недвижимость',
        },
        children: [
          {
            name: '/realty/:tab/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['edit', 'realty-zayavka'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
    ],
  },
  {
    path: '/personal',
    name: '/personal',
    meta: {
      layout: 'blank-layout',
    },
    component: PersonalView,
  },
  {
    path: '/personal/:tab',
    name: '/personal/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: PersonalView,
    children: [
      {
        name: '/personal/:tab/employment',
        path: 'employment',
        meta: {
          mode: ['employment'],
          label: 'Выгрузка реестра',
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/employment_parser',
        path: 'employment_parser',
        meta: {
          mode: ['employment_parser'],
          label: 'Парсер реестра',
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/add',
        path: 'add',
        meta: {
          mode: ['add'],
        },
        component: Detail,
        children: [
          {
            name: '/personal/:tab/add/:id',
            path: ':id',
            meta: {
              mode: ['add', 'id'],
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/add/new',
            path: 'new',
            meta: {
              mode: ['add', 'new'],
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/personal/:tab/direction/:id',
        path: 'direction/:id',
        meta: {
          mode: ['direction'],
          label: 'Изменить направление',
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/bind/:id',
        path: 'bind/:id',
        meta: {
          mode: ['bind'],
          label: 'Привязать объект',
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/add-key',
        path: 'add-key',
        meta: {
          mode: ['add-key'],
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
      },
      {
        name: '/personal/:tab/:id/:cardTab',
        path: ':id/:cardTab',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
        children: [
          {
            name: '/personal/:tab/:id/:cardTab/new_scan',
            path: 'new_scan',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:scan',
            path: ':scan',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/new_card',
            path: 'new_card',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:payment',
            path: ':payment',
            meta: {
              mode: ['edit', 'edit-payment'],
              label: 'Начисление',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['edit', 'edit-zayavka'],
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/new',
            path: 'new',
            meta: {
              mode: ['edit', 'new'],
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:object_id',
            path: ':object_id',
            meta: {
              mode: ['edit', 'object_id'],
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/edit_habitation',
            path: 'edit_habitation',
            meta: {
              mode: ['edit', 'edit_habitation'],
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/add-x5',
            path: 'add-x5',
            meta: {
              mode: ['edit', 'add-x5'],
              label: 'ID X5',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/:x5',
            path: ':x5',
            meta: {
              mode: ['edit', 'edit-x5'],
              label: 'ID X5',
            },
            component: Detail,
          },
          {
            name: '/personal/:tab/:id/:cardTab/debt_add',
            path: 'debt_add',
            meta: {
              mode: ['edit', 'debt-add'],
              label: 'Создание задолженности',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/personal/:tab/:key_id',
        path: ':key_id',
        meta: {
          mode: ['edit-key'],
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/pivot',
    name: '/pivot',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PivotView,
    children: [
      {
        name: '/pivot-output',
        path: '/pivot/output',
        meta: {
          mode: ['output'],
          label: 'Выработка',
        },
        component: Detail,
      },
      {
        name: '/pivot-profit',
        path: '/pivot/profit',
        meta: {
          mode: ['profit'],
          label: 'Начислить',
        },
        component: Detail,
      },
      {
        name: '/pivot-profit-cleaning',
        path: '/pivot/profit-cleaning',
        meta: {
          mode: ['profit-cleaning'],
          label: 'Начислить',
        },
        component: Detail,
      },
      {
        name: '/write-c3',
        path: '/pivot/write-c3',
        meta: {
          mode: ['write-c3'],
          label: 'Печать СЗ',
        },
        component: Detail,
      },
      {
        name: '/pivot/payment/:id',
        path: '/pivot/payment/:id',
        meta: {
          mode: ['edit'],
          label: 'Назначение',
        },
        component: Detail,
      },
      {
        name: '/pivot/personal/:id',
        path: '/pivot/personal/:id',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
      },
      {
        name: '/pivot/personal/:id/:cardTab',
        path: '/pivot/personal/:id/:cardTab',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
        children: [
          {
            name: '/pivot/personal/:id/:cardTab/:payment',
            path: ':payment',
            meta: {
              mode: ['personal', 'personal-payment'],
              label: 'Начисление',
            },
            component: Detail,
          },
          {
            name: '/pivot/personal/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['personal', 'personal-zayavka'],
              label: 'Заявка',
            },
            component: Detail,
          },
          {
            name: '/pivot/personal/:id/:cardTab/:scan',
            path: ':scan',
            meta: {
              mode: ['personal', 'personal-scan'],
              label: 'Скан',
            },
            component: Detail,
          },
          {
            name: '/pivot/personal/:id/:cardTab/:card',
            path: ':card',
            meta: {
              mode: ['personal', 'personal-card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/pivot-object',
        path: '/pivot/:id',
        meta: {
          mode: ['object'],
          label: 'Объект',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/pivotx5',
    name: '/pivotx5',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Pivotx5View,
  },
  {
    path: '/magnit_pivot',
    name: '/magnit_pivot',
    meta: {
      layout: 'blank-layout',
    },
    component: MagnitPivot,
  },
  {
    path: '/magnit_pivot/:tab',
    name: '/magnit_pivot/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: MagnitPivot,
    children: [
      {
        name: '/magnit_pivot/:tab/payment/:id',
        path: 'payment/:id',
        meta: {
          mode: ['edit'],
          label: 'Заявка "Магнит"',
        },
        component: Detail,
      },
      {
        name: '/magnit_pivot/:tab/payment/:id/:cardTab',
        path: 'payment/:id/:cardTab',
        meta: {
          mode: ['edit'],
          label: 'Заявка "Магнит"',
        },
        component: Detail,
        children: [
          {
            name: '/magnit_pivot/:tab/zayavka/:id/:cardTab/add',
            path: 'add',
            meta: {
              mode: ['edit', 'add-edit-logistic'],
              label: 'Добавить начисление',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/zayavka/:id/:cardTab/:payment_id',
            path: ':payment_id',
            meta: {
              mode: ['edit', 'add-edit-logistic'],
              label: 'Добавить начисление',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/magnit_pivot/:tab/personal/:id',
        path: 'personal/:id',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
      },
      {
        name: '/magnit_pivot/:tab/personal/:id/:cardTab',
        path: 'personal/:id/:cardTab',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
        children: [
          {
            name: '/magnit_pivot/:tab/personal/:id/:cardTab/:payment',
            path: ':payment',
            meta: {
              mode: ['personal', 'personal-payment'],
              label: 'Начисление',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/personal/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['personal', 'personal-zayavka'],
              label: 'Заявка на расход',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/personal/:id/:cardTab/:scan',
            path: ':scan',
            meta: {
              mode: ['personal', 'personal-scan'],
              label: 'Скан',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/personal/:id/:cardTab/scan-add',
            path: 'new',
            meta: {
              mode: ['personal', 'new'],
              label: 'Скан',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/personal/:id/:cardTab/:card',
            path: ':card',
            meta: {
              mode: ['personal', 'personal-card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/magnit_pivot/:tab/object/:id',
        path: 'object/:id',
        meta: {
          mode: ['object'],
          label: 'Объект',
        },
        component: Detail,
      },
      {
        name: '/magnit_pivot/:tab/object/:id/:cardTab',
        path: 'object/:id/:cardTab',
        meta: {
          mode: ['object'],
          label: 'Объект',
        },
        component: Detail,
      },
      {
        name: '/magnit_pivot/:tab/account/:id',
        path: 'account/:id',
        meta: {
          mode: ['account'],
          label: 'Аккаунт',
        },
        component: Detail,
      },
      {
        name: '/magnit_pivot/:tab/account/:id/:cardTab',
        path: 'account/:id/:cardTab',
        meta: {
          mode: ['account'],
          label: 'Аккаунт',
        },
        component: Detail,
        children: [
          {
            name: '/magnit_pivot/:tab/account/:id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['account', 'new_card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
          {
            name: '/magnit_pivot/:tab/account/:id/:cardTab/new_card',
            path: 'new_card',
            meta: {
              mode: ['account', 'new_card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/magnit_pivot/:tab/:id',
        path: ':id',
        meta: {
          mode: ['account'],
          label: 'Аккаунт',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/pivot_payment',
    name: '/pivot_payment',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PivotPaymentView,
    children: [
      {
        name: '/pivot_payment/payment/:id',
        path: 'payment/:id',
        meta: {
          mode: ['edit'],
          label: 'Начисление',
        },
        component: Detail,
        children: [
          {
            name: '/pivot_payment/payment/:id/change-personal',
            path: 'change-personal',
            meta: {
              mode: ['edit', 'change-personal'],
              label: 'Сменить линейщика',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/pivot_payment/personal/:id',
        path: 'personal/:id',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
      },
      {
        name: '/pivot_payment/personal/:id/:cardTab',
        path: 'personal/:id/:cardTab',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
        children: [
          {
            name: '/pivot_payment/personal/:id/:cardTab/:payment',
            path: ':payment',
            meta: {
              mode: ['personal', 'personal-payment'],
              label: 'Начисление',
            },
            component: Detail,
          },
          {
            name: '/pivot_payment/personal/:id/:cardTab/personal-zayavka',
            path: ':zayavka',
            meta: {
              mode: ['personal', 'personal-zayavka'],
              label: 'Заявка на расход',
            },
            component: Detail,
          },
          {
            name: '/pivot_payment/personal/:id/:cardTab/:scan',
            path: ':scan',
            meta: {
              mode: ['personal', 'personal-scan'],
              label: 'Скан',
            },
            component: Detail,
          },
          {
            name: '/pivot_payment/personal/:id/:cardTab/:card',
            path: ':card',
            meta: {
              mode: ['personal', 'personal-card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
          {
            name: '/pivot_payment/personal/:id/:cardTab/new',
            path: 'new',
            meta: {
              mode: ['personal', 'new'],
              label: 'Скан',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/pivot_payment/object/:id',
        path: '/pivot_payment/object/:id',
        meta: {
          mode: ['object'],
          label: 'Объект',
        },
        component: Detail,
      },
      {
        name: '/pivot_payment/object/:id/:cardTab',
        path: '/pivot_payment/object/:id/:cardTab',
        meta: {
          mode: ['object'],
          label: 'Объект',
        },
        component: Detail,
      },
      {
        name: '/pivot_payment/account/:id',
        path: '/pivot_payment/account/:id',
        meta: {
          mode: ['account'],
          label: 'Аккаунт',
        },
        component: Detail,
      },
      {
        name: '/pivot_payment/account/:id/:cardTab',
        path: '/pivot_payment/account/:id/:cardTab',
        meta: {
          mode: ['account'],
          label: 'Аккаунт',
        },
        component: Detail,
        children: [
          {
            name: '/pivot_payment/account/:id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['account', 'new_card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
          {
            name: '/pivot_payment/account/:id/:cardTab/new_card',
            path: 'new_card',
            meta: {
              mode: ['account', 'new_card'],
              label: 'Банковская карта',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/pivot_payment/output',
        path: '/pivot_payment/output',
        meta: {
          mode: ['output'],
          label: 'Парсер Х5',
        },
        component: Detail,
      },
      {
        name: '/pivot_payment/report',
        path: '/pivot_payment/report',
        meta: {
          mode: ['report'],
          label: 'Отчет А/В',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/x5import',
    name: '/x5import',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: X5importView,
    children: [
      {
        name: '/x5import/:id',
        path: ':id',
        component: Detail,
      },
    ],
  },
  {
    path: '/x5payment',
    name: 'x5payment',
    meta: {
      layout: 'blank-layout',
    },
    component: X5paymentView,
    children: [],
  },
  {
    path: '/x5territories',
    name: '/x5territories',
    meta: {
      layout: 'blank-layout',
    },
    component: X5territoriesView,
    children: [
      {
        name: '/x5territories/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить территорию',
        },
        component: Detail,
      },
      {
        name: '/x5territories/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать территорию',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/x5zone',
    name: '/x5zone',
    meta: {
      layout: 'blank-layout',
    },
    component: X5zoneView,
    children: [
      {
        name: '/x5zone/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить зону',
        },
        component: Detail,
      },
      {
        name: '/x5zone/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать зону',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/object',
    name: '/object',
    meta: {
      layout: 'blank-layout',
    },
    component: ObjectView,
  },
  {
    path: '/object/:tab',
    name: '/object/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: ObjectView,
    children: [
      {
        name: '/object/:tab/add',
        path: 'add',
        meta: {
          mode: ['add'],
        },
        component: Detail,
      },
      {
        name: '/object/:tab/appoint',
        path: 'appoint',
        meta: {
          mode: ['appoint'],
        },
        component: Detail,
      },
      {
        name: '/object/:tab/reassign',
        path: 'reassign',
        meta: {
          mode: ['reassign'],
          label: 'Переназначить',
        },
        component: Detail,
      },
      {
        name: '/object/:tab/tarif',
        path: 'tarif',
        meta: {
          mode: ['tarif'],
          label: 'Смена тарифа',
        },
        component: Detail,
      },
      {
        name: '/object/:tab/load',
        path: 'load',
        meta: {
          mode: ['load'],
          label: 'Загрузить тариф',
        },
        component: Detail,
      },
      {
        name: '/object/:tab/tarif_parser',
        path: 'tarif_parser',
        meta: {
          mode: ['tarif_parser'],
          label: 'Парсер тарифа',
        },
        component: Detail,
      },
      {
        name: '/object/:tab/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Объект - редактирование',
        },
        component: Detail,
      },
      {
        name: '/object/:tab/:id/:cardTab',
        path: ':id/:cardTab',
        meta: {
          mode: ['edit'],
          label: 'Объект - редактирование',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/account',
    name: '/account',
    meta: {
      layout: 'blank-layout',
    },
    component: AccountView,
  },
  {
    path: '/account/:tab',
    name: '/account/:tab',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AccountView,
    children: [
      {
        name: '/account/:tab/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавление аккаунта',
        },
        component: Detail,
      },
      {
        name: '/account/:tab/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактирования аккаунта',
        },
        component: Detail,
      },
      {
        name: '/account/:tab/:id/:cardTab',
        path: ':id/:cardTab',
        component: Detail,
        meta: {
          mode: ['edit'],
          label: 'Редактирования аккаунта',
        },
        children: [
          {
            name: '/account/:tab/:id/:cardTab/new_card',
            path: 'new_card',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/account/:tab/:id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['edit', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
        ],
      },
    ],
  },
  {
    path: '/shop-request-magnit',
    name: '/shop-request-magnit',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ShopRequestMagnitView,
    children: [
      {
        name: '/shop-request-magnit/add',
        path: '/shop-request-magnit/add',
        component: Detail,
        meta: {
          label: 'Добавление заявки на Магнит',
          mode: ['add-or-edit'],
        },
        children: [
          {
            name: '/shop-request-magnit-add-payment/add',
            path: '/shop-request-magnitpayment/add',
            meta: {
              mode: ['add-edit-logistic'],
              label: 'Добавить начисление',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/shop-request-magnit/:id',
        path: '/shop-request-magnit/:id',
        component: Detail,
        meta: {
          label: 'Редактирование заявки на Магнит',
          mode: ['add-or-edit'],
        },
        children: [
          {
            name: '/shop-request-magnit/:id/payment-add',
            path: '/shop-request-magnit/:id/payment-add',
            meta: {
              mode: ['add-or-edit', 'add-edit-logistic'],
              label: 'Добавить начисление',
            },
            component: Detail,
          },
          {
            name: '/shop-request-magnit/:id/payment/:payment_id',
            path: '/shop-request-magnit/:id/payment/:payment_id',
            meta: {
              mode: ['add-or-edit', 'add-edit-logistic'],
              label: 'Добавить начисление',
            },
            component: Detail,
          },
        ],
      },
      {
        name: '/shop-request-magnit/upload',
        path: './upload',
        meta: {
          mode: ['upload'],
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/shop-request-magnit-report',
    name: '/shop-request-magnit-report',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ShopRequestMagnitReportView,
    children: [
      {
        name: '/shop-request-magnit-report/:id',
        path: ':id',
        component: Detail,
      },
    ],
  },
  {
    path: '/report_magnit',
    name: '/report_magnit',
    meta: {
      layout: 'blank-layout',
    },
    component: ReportMagnit,
    children: [],
  },
  {
    path: '/report_filial',
    name: '/report_filial',
    meta: {
      layout: 'blank-layout',
    },
    component: ReportFilial,
    children: [],
  },
  {
    path: '/user-keys',
    name: '/user-keys',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UserKeysView,
    children: [
      {
        name: '/user-keys/:id',
        path: ':id',
        component: Detail,
      },
    ],
  },
  {
    path: '/report_taxi',
    name: '/report_taxi',
    meta: {
      layout: 'blank-layout',
    },
    component: ReportTaxiView,
    children: [
      {
        name: '/report_taxi-edit',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
      },
      {
        name: '/report_taxi/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/bank',
    name: '/bank',
    meta: {
      layout: 'blank-layout',
    },
    component: BankView,
    children: [
      {
        name: '/bank/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить банк',
        },
        component: Detail,
      },
      {
        name: '/bank/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать банк',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/jur-face',
    name: '/jur-face',
    meta: {
      layout: 'blank-layout',
    },
    component: JurFaceView,
    children: [
      {
        name: '/jur-face/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить юр. лицо',
        },
        component: Detail,
      },
      {
        name: '/jur-face/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать юр. лицо',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/rashod_vid',
    name: '/rashod_vid',
    meta: {
      layout: 'blank-layout',
    },
    component: RashodVidView,
    children: [
      {
        name: '/rashod_vid/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить расход',
        },
        component: Detail,
      },
      {
        name: '/rashod_vid/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать расход',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/rashod_category',
    name: '/rashod_category',
    meta: {
      layout: 'blank-layout',
    },
    component: RashodCategoryView,
    children: [
      {
        name: '/rashod_category/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить расход',
        },
        component: Detail,
      },
      {
        name: '/rashod_category/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать расход',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/mvd',
    name: '/mvd',
    meta: {
      layout: 'blank-layout',
    },
    component: MvdView,
    children: [
      {
        name: '/mvd/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить МВД',
        },
        component: Detail,
      },
      {
        name: '/mvd/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать МВД',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/service',
    name: '/service',
    meta: {
      layout: 'blank-layout',
    },
    component: ServiceView,
    children: [
      {
        name: '/service/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить сервис',
        },
        component: Detail,
      },
      {
        name: '/service/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать сервис',
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/habitation',
    name: '/habitation',
    meta: {
      layout: 'blank-layout',
    },
    component: HabitationView,
  },
  {
    path: '/habitation/:tab',
    name: '/habitation/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: HabitationView,
    children: [
      {
        name: '/habitation/:tab/add',
        path: 'add',
        meta: {
          mode: ['habitation-add'],
          label: 'Добавить проживание',
        },
        children: [],
        component: Detail,
      },
      {
        name: '/habitation/:tab/:habitation_id',
        path: ':habitation_id',
        meta: {
          mode: ['habitation-edit'],
          label: 'Редактировать проживание',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/:habitation_id/:cardTab',
        path: ':habitation_id/:cardTab',
        meta: {
          mode: ['habitation-edit'],
          label: 'Редактировать проживание',
        },
        children: [
          {
            name: '/habitation/:tab/:habitation_id/:cardTab/add_owner',
            path: 'add_owner',
            meta: {
              mode: ['habitation-edit', 'habitation-owner-add'],
              label: 'Добавить владельца',
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:habitation_id/:cardTab/add_realtor',
            path: 'add_realtor',
            meta: {
              mode: ['habitation-edit', 'habitation-realtor-add'],
              label: 'Добавить риэлтора',
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:habitation_id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['habitation-edit', 'habitation-zayavka-edit'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
      {
        name: '/habitation/:tab/add_owner',
        path: 'add_owner',
        meta: {
          mode: ['habitation-owner-add'],
          label: 'Добавить владельца',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/:owner_id',
        path: ':owner_id',
        meta: {
          mode: ['habitation-owner-edit'],
          label: 'Редактировать владельца',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/:owner_id/:cardTab',
        path: ':owner_id/:cardTab',
        meta: {
          mode: ['habitation-owner-edit'],
          label: 'Редактировать владельца',
        },
        children: [
          {
            name: '/habitation/:tab/:owner_id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['habitation-owner-edit', 'new_card'],
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:owner_id/:cardTab/add',
            path: 'add',
            meta: {
              mode: ['habitation-owner-edit', 'add'],
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:owner_id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['habitation-owner-edit', 'habitation-zayavka-edit'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },

      //TODO: Калл какой-то
      {
        name: '/habitation/:tab/card_add',
        path: 'add_realtor',
        meta: {
          mode: ['habitation-realtor-add'],
          label: 'Добавить риэлтора',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/add_realtor',
        path: 'add_realtor',
        meta: {
          mode: ['habitation-realtor-add'],
          label: 'Добавить риэлтора',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/:realtor_id',
        path: ':realtor_id',
        meta: {
          mode: ['habitation-realtor-edit'],
          label: 'Редактировать риэлтора',
        },
        component: Detail,
      },
      {
        name: '/habitation/:tab/:realtor_id/:cardTab',
        path: ':realtor_id/:cardTab',
        meta: {
          mode: ['habitation-realtor-edit'],
          label: 'Редактировать риэлтора',
        },
        children: [
          {
            name: '/habitation/:tab/:realtor_id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['habitation-realtor-edit', 'new_card'],
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:realtor_id/:cardTab/card_add',
            path: 'card_add',
            meta: {
              mode: ['habitation-realtor-edit', 'add'],
            },
            component: Detail,
          },
          {
            name: '/habitation/:tab/:realtor_id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['habitation-realtor-edit', 'habitation-zayavka-edit'],
            },
            component: Detail,
          },
        ],
        component: Detail,
      },
    ],
  },
  {
    path: '/zayavka',
    name: '/zayavka',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ZayavkaView,
    children: [
      {
        name: '/zayavka/add',
        path: '/zayavka/add',
        meta: {
          mode: ['add'],
        },
        component: Detail,
      },
      {
        name: '/zayavka/load',
        path: 'load',
        meta: {
          mode: ['load'],
        },
        component: Detail,
      },
      {
        name: '/zayavka/:id',
        path: ':id',
        meta: {
          mode: ['id'],
        },
        component: Detail,
      },
    ],
  },
  {
    path: '/navbar',
    name: '/navbar',
    meta: {
      layout: 'blank-layout',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Navbar,
  },
  //{
  //  path: '/testts',
  //  name: 'TestTs',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: TestTs,
  //},
  {
    path: '/login',
    name: '/Login',
    meta: {
      layout: 'login-layout',
    },
    component: LoginView,
  },
  {
    path: '/mails',
    name: '/mails',
    meta: {
      layout: 'blank-layout',
    },
    component: MailView,
  },
  {
    path: '/documents',
    name: '/documents',
    meta: {
      layout: 'blank-layout',
    },
    component: DocumentView,
  },
  {
    path: '/documents/:tab',
    name: '/documents/:tab',
    meta: {
      layout: 'blank-layout',
    },
    component: DocumentView,
    children: [
      {
        name: '/documents/:tab/query',
        path: 'query',
        meta: {
          mode: ['query'],
        },
        component: Detail,
      },
      {
        name: '/documents/:tab/:id',
        path: ':id',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
      },
      {
        name: '/documents/:tab/:id/:cardTab',
        path: ':id/:cardTab',
        meta: {
          mode: ['personal'],
          label: 'Персонал',
        },
        component: Detail,
        children: [
          {
            name: '/documents/:tab/:id/:cardTab/new_card',
            path: 'new_card',
            meta: {
              mode: ['personal', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/:object_id',
            path: ':object_id',
            meta: {
              mode: ['personal', 'object_id'],
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/:card_id',
            path: ':card_id',
            meta: {
              mode: ['personal', 'new_card'],
              // label: 'Добавить аккаунта',
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/:payment',
            path: ':payment',
            meta: {
              mode: ['personal', 'personal-payment'],
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/:zayavka',
            path: ':zayavka',
            meta: {
              mode: ['personal', 'personal-zayavka'],
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/new',
            path: 'new',
            meta: {
              mode: ['personal', 'new'],
            },
            component: Detail,
          },
          {
            name: '/documents/:tab/:id/:cardTab/edit_habitation',
            path: 'edit_habitation',
            meta: {
              mode: ['personal', 'edit_habitation'],
            },
            component: Detail,
          },

          // {
          //   name: 'documents/:id/new_card',
          //   path: '/documents/:id/new_card',
          //   meta: {
          //     mode: ['personal', 'new_card'],
          //     // label: 'Добавить аккаунта',
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/:card_id',
          //   path: '/documents/:id/:card_id',
          //   meta: {
          //     mode: ['personal', 'new_card'],
          //     // label: 'Добавить аккаунта',
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/new',
          //   path: '/documents/:id/new',
          //   meta: {
          //     mode: ['personal', 'new'],
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/:object_id',
          //   path: '/documents/:id/:object_id',
          //   meta: {
          //     mode: ['personal', 'object_id'],
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/edit_habitation',
          //   path: '/documents/:id/edit_habitation',
          //   meta: {
          //     mode: ['personal', 'edit_habitation'],
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/:payment',
          //   path: '/documents/:id/:payment',
          //   meta: {
          //     mode: ['personal', 'personal-payment'],
          //   },
          //   component: Detail,
          // },
          // {
          //   name: 'documents/:id/:zayavka',
          //   path: '/documents/:id/:zayavka',
          //   meta: {
          //     mode: ['personal', 'personal-zayavka'],
          //   },
          //   component: Detail,
          // },
        ],
      },
    ],
  },
  {
    path: '/slata-report',
    name: '/slata-report',
    meta: {
      layout: 'blank-layout',
    },
    component: SlataReportView,
  },
  {
    path: '/corporate-cards',
    name: '/corporate-cards',
    meta: {
      layout: 'blank-layout',
    },
    children: [
      {
        name: '/corporate-cards/add',
        path: 'add',
        meta: {
          mode: ['add'],
          label: 'Добавить карту',
        },
        component: Detail,
      },
      {
        name: '/corporate-cards/:card_id',
        path: ':card_id',
        meta: {
          mode: ['give'],
          label: 'Выдать карту',
        },
        component: Detail,
      },
      {
        name: '/corporate-cards/:id',
        path: ':id',
        meta: {
          mode: ['edit'],
          label: 'Редактировать карту',
        },
        component: Detail,
      },
      {
        name: '/corporate-cards/:history_id',
        path: ':history_id',
        meta: {
          mode: ['history'],
          label: 'История',
        },
        component: Detail,
      },
    ],
    component: CorporateCardsView,
  },
  {
    path: '/contract',
    name: 'contract',
    meta: {
      layout: 'blank-layout',
    },
    component: ContractView,
  },
  {
    path: '/schedule',
    name: '/schedule',
    meta: {
      layout: 'blank-layout',
    },
    component: ScheduleView,
    children: [
      {
        name: '/schedule/add',
        path: '/schedule/add',
        meta: {
          mode: 'add',
        },
        component: Detail,
      },
      {
        name: '/schedule/new',
        path: '/schedule/new',
        meta: {
          mode: 'new',
        },
        component: Detail,
      },
      {
        name: '/schedule/edit',
        path: '/schedule/edit',
        meta: {
          mode: 'edit',
          label: 'Редактирование',
        },
        component: Detail,
      },
      {
        name: '/schedule/payment/:id',
        path: 'payment/:id',
        component: Detail,
      },
    ],
  },
  {
    path: '/schedule',
    name: '/schedule',
    meta: {
      layout: 'blank-layout',
    },
    component: ScheduleView,
    children: [
      {
        name: '/schedule/add',
        path: '/schedule/add',
        meta: {
          mode: 'add',
        },
        component: Detail,
      },
      {
        name: '/schedule/new',
        path: '/schedule/new',
        meta: {
          mode: 'new',
        },
        component: Detail,
      },
      {
        name: '/schedule/edit',
        path: '/schedule/edit',
        meta: {
          mode: 'edit',
          label: 'Редактирование',
        },
        component: Detail,
      },
      {
        name: '/schedule/payment/:id',
        path: 'payment/:id',
        component: Detail,
      },
    ],
  },
  {
    path: '/notes',
    name: '/notes',
    meta: {
      layout: 'blank-layout',
    },
    component: NotesView,
    children: [
      {
        name: '/schedule/add',
        path: 'add',
        meta: {
          mode: 'add',
        },
        component: Detail,
      },
      {
        name: '/schedule/:id',
        path: ':id',
        meta: {
          mode: 'edit',
        },
        component: Detail,
      },
    ],
  },
]

// Добавил фикс для варнингов - причина не известна, решение: https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: './',
  routes,
})

export default router
