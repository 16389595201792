<script>
export default {
  props: {
    payload: Object,
    cell: Object,
  },
  setup(props) {
    const typesColor = {
      1: '#D7B12A3A',
      2: '#A737283A',
      3: '#6051BD3A',
    }
    return {
      typesColor,
    }
  },
}
</script>
<template>
  <div
    class="pivotCell pa-2 ma-2"
    :class="!cell.added ? 'header' : 'cell'"
    @dblclick="() => $emit('dblclick', payload, cell)"
    :style="{ backgroundColor: typesColor[payload.type_shift] }"
  >
    <template v-if="cell.added">
      <div>
        <p>{{ payload.doljnost_name }}</p>
      </div>
      <div>
        <p style="color: var(--v-textGray-base)">{{ payload.object_name }}</p>
      </div>
    </template>
    <template v-else>
      <div>
        <p>{{ payload }}</p>
      </div>
    </template>
  </div>
</template>
<style>
.pivotCell {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  &.cell {
    width: auto;
    border-radius: 4px;
    &:hover {
      transition: 0.2s;
      padding: 4px;
      box-shadow: 0px 4px 4px 0px #25252540;
    }
  }
  p {
    text-align: left;
    user-select: none;
    text-overflow: ellipsis;
  }
}
</style>
