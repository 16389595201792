import router from '@/router'

let requestIdMap = new Set()
let addNumber = 0

export default function ({
  props,
  activeTab,
  availableTabsAll,
  popupForm,
  addCount,
}) {
  let requestId = props?.detail?.requestId
  let params = Object.entries(router.currentRoute.params)
  let routeParts = router.currentRoute.path.split('/').slice(1)
  let currentPos = routeParts.indexOf(
    `${router.currentRoute.params[requestId] ?? router.currentRoute.params.id}`
  )

  /** Удаляет из Set не используемые параметры url */
  const decrimentSet = (requestId) => {
    requestId ??= 'id'
    delete router.currentRoute.params[requestId]
    requestIdMap.delete(requestId)
  }

  /** Проверка есть ли еще модалки после текущей */
  const isModalLast = () => {
    const requestIdPos = [...requestIdMap.keys()].indexOf(requestId)
    currentPos = requestIdPos === -1 ? (requestIdMap.size + 1) * 2 : currentPos
    routeParts = router.currentRoute.path.split('/').slice(1)
    if (!router.currentRoute.params.tab && currentPos === 2) currentPos--
    if (addNumber) currentPos += addNumber

    return !routeParts[currentPos]
  }

  /** Возвращаяет отсортированный по порядку массив [`key`,`value`], содержащий url параметры */
  const calcSortedParams = () => {
    const splittedPath = router.currentRoute.path.split('/')
    const splittedName = router.currentRoute.name.split('/')
    let arr = []
    for (let i = 0; i < splittedName.length; i++) {
      if (splittedName[i].includes(':')) {
        arr.push([splittedName[i].slice(1), splittedPath[i]])
      }
    }
    return arr
  }

  /** Удаляет не задействованные параметры в данном `currentRoute.params` */
  const clearUnusedParams = () => {
    params = calcSortedParams()
    for (let i = 0; i < params.length; i++) {
      if (!router.currentRoute.name.includes(params[i][0])) {
        delete router.currentRoute.params[params[i][0]]
      }
    }
    router.push({
      name: router.currentRoute.matched.at(-2)?.path,
      replace: true,
    })
    // Рекурсивно закрывает вложенность после
    if (!isModalLast()) {
      clearUnusedParams()
    }
  }

  //Tabs
  /**
   * Используется в компоненте `Tabs`.
   * Проверяет существует ли указанный в ссылке tab и устанавливает в обратном случае первый доступный
   */
  const setFirstAvailableTab = (options = {}) => {
    requestIdMap.clear()
    const { incrementNum, closeFixed } = options
    if (closeFixed) {
      addNumber = 0
      return
    }
    if (incrementNum) addNumber = incrementNum
    routeParts = router.currentRoute.path.split('/').slice(1)
    activeTab.value = props.data.tabs.findIndex((x) => {
      return x.options.route === router.currentRoute.params.tab
    })

    if (activeTab.value === -1) {
      router.replace({
        name: `${router.currentRoute.matched[0].path}/:tab`,
        params: { tab: props.data.tabs[0].options.route },
      })
      activeTab.value = 0
      return
    }
  }

  /**
   * Используется в компонентах `table` `Default/Fixed`
   * Проверяет нужно ли показывать модалку при текущем url
   */
  const calcShowPopup = () => {
    requestId = props.options.detail.requestId || 'id'
    routeParts = router.currentRoute.path.split('/').slice(1)
    currentPos = routeParts.indexOf(
      `${
        router.currentRoute.params[requestId] ?? router.currentRoute.params.id
      }`
    )
    const requestIdPos = [...requestIdMap.keys()].indexOf(requestId)
    currentPos =
      requestIdPos !== -1 ? (requestIdPos + 1) * 2 : (requestIdMap.size + 1) * 2
    if (!router.currentRoute.params.tab && currentPos === 2) currentPos--
    if (addNumber) currentPos += addNumber

    if (
      !router.currentRoute.params.tab &&
      routeParts[currentPos] &&
      currentPos != -1
    ) {
      popupForm.value.isShow = true
      return
    }
    if (
      router.currentRoute.params.tab &&
      routeParts[currentPos] &&
      currentPos != -1
    ) {
      popupForm.value.isShow = true
      return
    }
    popupForm.value.isShow = false
  }

  /** Используется в компоненте `detail`.
   * Проверяет нужно ли показывать форму или сущность/таб.
   * Проверяет наличие введенного таба среди доступных.
   * При не соотвествии сущности ожидаемой, подменяет на нужную.
   * Заносит по порядку сущности в `Set`.
   */
  const calcDetailVisibility = () => {
    params = calcSortedParams()
    requestId ??= 'id'
    routeParts = router.currentRoute.path.split('/').slice(1)

    currentPos = (requestIdMap.size + 1) * 2

    if (addNumber) currentPos += addNumber
    if (!router.currentRoute.params.tab) {
      params = [[null], ...params]
    }

    let paramOnThisPos = params[currentPos - 1] || ['', routeParts[currentPos]]
    if (addNumber) paramOnThisPos = ['', routeParts[currentPos]]
    // [Число]
    if (!isNaN((paramOnThisPos || [])[1])) {
      // То ли название параметра
      if (paramOnThisPos[0] && paramOnThisPos[0] != requestId) {
        const leftRigthParts = router.currentRoute.name.split(
          `/:${paramOnThisPos[0]}`
        )
        delete router.currentRoute.params[paramOnThisPos[0]]
        router.currentRoute.params[requestId] = paramOnThisPos[1]
        router.push({
          name: `${leftRigthParts[0]}/:${requestId}${leftRigthParts[1]}`,
          replace: true,
        })
      }

      if (addNumber) params = [null, ...params]
      // Есть ли в ссылке Таб

      if (params[currentPos]) {
        activeTab.value = availableTabsAll.value.findIndex((x) => {
          return x.route === params[currentPos][1]
        })
        // Не Существующий ли Таб
        if (activeTab.value === -1 ?? availableTabsAll.value[0]) {
          router.replace({
            params: {
              [params[currentPos][0]]: availableTabsAll.value[0].route,
            },
          })
          activeTab.value = 0
          return
        }
      }
      // Вставить первый попавшийся Таб
      else {
        // Есть ли доступные Табы
        if (
          availableTabsAll.value.filter((x) => {
            return x.route
          }).length <= 1
        ) {
          requestIdMap.add(requestId)
          return
        }
        router.replace({
          name: `${router.currentRoute.name}/:cardTab`,
          params: { cardTab: availableTabsAll.value[0].route },
        })
        activeTab.value = 0
      }
      requestIdMap.add(requestId)
    }
    // [Форма]
    else {
      if (!paramOnThisPos[1]) return
      router.push({
        name: `${router.currentRoute.matched.at(-2).path}/${
          (paramOnThisPos || [])[1]
        }`,
        replace: true,
      })
    }
  }
  return {
    decrimentSet,
    isModalLast,
    clearUnusedParams,
    setFirstAvailableTab,
    calcDetailVisibility,
    calcShowPopup,
    addNumber,
  }
}
