<script>
export default {
  props: {
    payload: Object,
    cell: Object,
  },
  setup(props) {
    const typesColor = {
      1: '#D7B12A',
      2: '#A73728',
      3: '#6051BD',
    }
    return {
      typesColor,
    }
  },
}
</script>
<template>
  <div
    class="noteItemCell pa-2"
    :class="!cell.added ? 'header' : 'cell'"
    @dblclick="() => $emit('dblclick', payload, cell)"
  >
    <template v-if="cell.added"> 123 </template>
    <template v-else>
      <div>
        <p>{{ payload }}</p>
      </div>
    </template>
  </div>
</template>
<style scoped>
.noteItemCell {
  display: flex;
  height: 50px;
  flex-flow: column nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.cell {
    width: 50px;
    border-radius: 4px;
  }
  p {
    text-align: center;
    user-select: none;
    text-overflow: ellipsis;
  }
}
</style>
