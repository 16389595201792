import { computed, ref } from 'vue'
import store from '@/store'
import customNotify from './customNotify/index.vue'

export default {
  name: 'Notifies',
  components: {
    customNotify,
  },
  setup() {
    const notifiesList = computed(() => {
      return store.state.notifies
    })
    const hideMessage = () => {
      store.commit('notifies/hideMessage')
    }
    return {
      notifiesList,
      hideMessage,
    }
  },
}
